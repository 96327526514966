import { dangerColor, grayColor } from '../../assets/jss/materialKitBase';
import { createStyles, Theme } from '@material-ui/core';

export const priceStyle = (theme: Theme) =>
  createStyles({
    priceContainer: {
      display: 'inline-flex',
    },
    price: {
      // fontSize: '18px',
      color: grayColor[9],
      fontWeight: 900,
    },
    priceOld: {
      // fontSize: '16px',
      textDecoration: 'line-through',
    },
    priceNew: {
      color: dangerColor[0],
    },
  });
