import { ProductNode } from '../../interfaces/contentful';
import React, { useContext } from 'react';
import { CartDispatchContext } from '../../store/StateProvider';
import { ShoppingCart } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import { Button, CustomButtonProps } from './Button';
import { Tooltip } from '@material-ui/core';

interface AddToCartButtonProps {
  product: ProductNode;
  quantity: number;
  size?: CustomButtonProps['size'];
  onlyIcon?: boolean;
}

export const AddToCartButton: React.FC<AddToCartButtonProps> = ({ product, quantity, size, onlyIcon = false }) => {
  const dispatch = useContext(CartDispatchContext);
  const notAvailable = Boolean(product.notAvailableReason);

  const dispatchAddToCart = () => {
    dispatch({
      type: 'cart/addToCart',
      payload: {
        newProduct: product,
        quantity: quantity,
      },
    });
  };

  if (notAvailable) {
    return (
      <Tooltip title={String(product.notAvailableReason)} arrow>
        <span style={notAvailable ? { cursor: 'help' } : {}}>
          <Button disabled={notAvailable} size={size} round color="rose" onClick={dispatchAddToCart}>
            {onlyIcon && <ShoppingCart />}
            {!onlyIcon && (
              <>
                <FormattedMessage id="general.addToCart" defaultMessage="Add to Cart" /> &nbsp; <ShoppingCart />
              </>
            )}
          </Button>
        </span>
      </Tooltip>
    );
  } else {
    return (
      <Button size={size} round color="rose" onClick={dispatchAddToCart}>
        {onlyIcon && <ShoppingCart />}
        {!onlyIcon && (
          <>
            <FormattedMessage id="general.addToCart" defaultMessage="Add to Cart" /> &nbsp; <ShoppingCart />
          </>
        )}
      </Button>
    );
  }
};
