import React from 'react';
import classNames from 'classnames';
import { formatPrice } from '../../helpers/formatterHelper';
import { ProductNode } from '../../interfaces/contentful';
import { priceStyle } from './PriceStyle';
import { makeStyles } from '@material-ui/core/styles';

interface PriceProps {
  product: ProductNode;
}

const useStyles = makeStyles(priceStyle);

export const Price: React.FC<PriceProps> = ({ product }) => {
  const classes = useStyles();

  return (
    <div className={classes.priceContainer}>
      <span className={classes.price}>{formatPrice(product.price)}</span>
    </div>
  );
};
